.label {
  @apply text-sm font-medium text-gray-700 w-full;
}

.labelInline {
  @apply block text-sm font-medium text-gray-700 mr-4;
}

.input {
  @apply block border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500;
}

.input:not(.fit) {
  @apply w-full;
}

.error {
  @apply text-red-600 text-sm;
}

.timeInput :global(.react-datepicker__input-time-container) {
  @apply m-0 p-2;
}

.timeInput :global(.react-datepicker__today-button),
.timeInput :global(.react-datepicker__input-time-container) {
  @apply bg-transparent text-center;
}
